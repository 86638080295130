import React from 'react'
import { Link } from 'react-router-dom'

export default function Training() {
  return (
    <div>
      <>
      <div
  className="container-fluid bg-primary py-5 bg-header"
  style={{ marginBottom: 90 }}
>
  <div className="row py-5">
    <div className="col-12 pt-lg-5 mt-lg-5 text-center">
      <h1 className="display-4 text-white animated zoomIn">Training</h1>
      <Link href="" className="h5 text-white">
        Home
      </Link>
      <i className="far fa-circle text-white px-2" />
      <Link href="" className="h5 text-white">
        Training
      </Link>
    </div>
  </div>
</div>
  <div className="modal fade" id="searchModal" tabIndex={-1}>
    <div className="modal-dialog modal-fullscreen">
      <div
        className="modal-content"
        style={{ background: "rgba(9, 30, 62, .7)" }}
      >
        <div className="modal-header border-0">
          <button
            type="button"
            className="btn bg-white btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body d-flex align-items-center justify-content-center">
          <div className="input-group" style={{ maxWidth: 600 }}>
            <input
              type="text"
              className="form-control bg-transparent border-primary p-3"
              placeholder="Type search keyword"
            />
            <button className="btn btn-primary px-4">
              <i className="bi bi-search" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Full Screen Search End */}
  {/* About Start */}
  <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-7">
          <div className="section-title position-relative pb-3 mb-5">
            <h5 className="fw-bold text-primary text-uppercase">Training</h5>
            <h1 className="mb-0">
            Classroom Training
            </h1>
          </div>
          <p className="mb-4">
  Classroom training is considered the most effective form of learning. Attending a class requires an investment of time and effort, but the rewards are significant. The classroom environment removes you from the distractions of everyday work so you can focus on improving your development skills.
</p>
<p className="mb-4">
  With MTIS classroom training, you have the opportunity to interact with an experienced certified instructor in person and discuss ideas and problems with your peers and colleagues.
</p>
<p className="mb-4">
  We believe that “The classroom is the first step where the students begin their journey towards the industry” and every second of their stay within our classrooms counts for the success in their career. To make the student sharpen his “Personality of Professionalism,” our classrooms are well-equipped by the infrastructure and architecture as demanded by the variants of software technologies.
</p>
        </div>
        <div className="col-lg-5" style={{ minHeight: 500 }}>
          <div className="position-relative h-100">
            <img
              className="position-absolute w-100 h-100 rounded wow zoomIn"
              data-wow-delay="0.9s"
              src="img/Classroom.jpg" alt="Classroom"
              style={{ objectFit:"cover" }}/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
  <div className="container py-5">
    <div className="row g-5">
      <div className="col-lg-5" style={{ minHeight: 500 }}>
        <div className="position-relative h-100">
          <img
            className="position-absolute w-100 h-100 rounded wow zoomIn"
            data-wow-delay="0.9s"
            src="img/Online.jpg"
            alt="hello"
            style={{ objectFit: "cover" }}
          />
        </div>
      </div>
      <div className="col-lg-7">
  <div className="section-title position-relative pb-3 mb-5">
    <h5 className="fw-bold text-primary text-uppercase">Traning</h5>
    <h1 className="mb-0">Online Training</h1>
  </div>
  <p className="mb-4">
  Following its legacy as a path breaker, MTIS offers Online Software Training using its vast Technology Expertise and Training Delivery Methodology, benefiting the student community by transforming their dreams from a wandering generality to specific reality.
</p>
<p className="mb-4">
  Our sole maxim in introducing Online Software Training is to cater to the training requirements of Software Aspirants who cannot attend Classroom Training conducted at our Office Locations due to various reasons.
</p>
<p className="mb-4">
  The Online Training program is conducted over the internet using the latest state-of-the-art software tools and via video and desktop sharing facilities. Training sessions are led by well-experienced faculty, mentored to meet the convenience of the participants.
</p>
        <div
          className="d-flex align-items-center mb-4 wow fadeIn"
          data-wow-delay="0.6s"
        ></div>
      </div>
    </div>
  </div>
</div>
<div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-7">
          <div className="section-title position-relative pb-3 mb-5">
            <h5 className="fw-bold text-primary text-uppercase">Training</h5>
            <h1 className="mb-0">
            Corporate Training
            </h1>
          </div>
          <p className="mb-4">
  Creation of MTIS and its successful journey are the result of inspiration from certain ideas—in other words, our collective dream. It’s this long-cherished dream that motivates us and propels us forward.
</p>
<p className="mb-4">
  To provide best-of-breed technology-based solutions, we aim to have a company of people who are proud of their work, enjoy collaborating, and continue to dream of making their company bigger, smarter, sweeter, reputed, and admirable.
</p>
<p className="mb-4">
  With this dream in mind, vision in sight, mission in hand, and values in heart, the company was established by a group of entrepreneurs led by an IT professional. Headquartered in Ranchi, it has two additional centers in Hyderabad and Coimbatore, contributing more experience in various verticals and technology platforms.
</p>

          <div
            className="d-flex align-items-center mb-4 wow fadeIn"
            data-wow-delay="0.6s"
          >
          </div>
        </div>
        <div className="col-lg-5" style={{ minHeight: 500 }}>
          <div className="position-relative h-100">
            <img
              className="position-absolute w-100 h-100 rounded wow zoomIn"
              data-wow-delay="0.9s"
              src="img/corpo.jpg" alt="hello"
              style={{ objectFit: "cover" }}/>
          </div>
        </div>
      </div>
    </div>
  </div>


  
</>

    </div>
  )
}
