import React from 'react'
import { Link } from 'react-router-dom'

export default function Navbar() {
  return (

<>
    {/* Topbar Start */}
    <div className="container-fluid bg-dark px-5 d-none d-lg-block">
      <div className="row gx-0">
        <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
          <div
            className="d-inline-flex align-items-center"
            style={{ height: 45 }}
          >  
          <marquee style={{ width: '700px' }}>
          <small className="me-3 text-light" >
  Voices of Satisfaction: What Our Customers Say about Our Growth.
</small>
         
            </marquee>
          </div>
        </div>
        <div className="col-lg-4 text-center text-lg-end">
          <div
            className="d-inline-flex align-items-center"
            style={{ height: 45 }}
          >
            <Link
              className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
              to=""
            >
              <i className="fab fa-twitter fw-normal" />
            </Link>
            <Link
              className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
              to=""
            >
              <i className="fab fa-facebook-f fw-normal" />
            </Link>
            <Link
              className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
              to=""
            >
              <i className="fab fa-linkedin-in fw-normal" />
            </Link>
            <Link
              className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
              to=""
            >
              <i className="fab fa-instagram fw-normal" />
            </Link>
            <Link
              className="btn btn-sm btn-outline-light btn-sm-square rounded-circle"
              to=""
            >
              <i className="fab fa-youtube fw-normal" />
            </Link>
          </div>
        </div>
      </div>
    </div>
    {/* Topbar End */}
  
    
    
<div className="container-fluid position-relative p-0">
  <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
    <Link to="index.html" className="navbar-brand p-0">
      <h1 className="m-0">
        <img src='img/mlogo.png' style={{height: "50px"}} alt=''/>
      </h1>
    </Link>
    <button
      className="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarCollapse"
    >
      <span className="fa fa-bars" />
    </button>
    <div className="collapse navbar-collapse" id="navbarCollapse">
      <div className="navbar-nav ms-auto py-0">
        <Link to="/" className="nav-item nav-link">
          Home
        </Link>
        <Link to="/about" className="nav-item nav-link">
          About Us
        </Link>
        <Link to="/course" className="nav-item nav-link">
          All Courses
        </Link>
        <Link to="/training" className="nav-item nav-link">
        Training
        </Link>

        <div className="nav-item dropdown">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
          >
            Careers
          </Link>
          <div className="dropdown-menu m-0">
            <Link to="price.html" className="dropdown-item">
              Interships
            </Link>
            <Link to="feature.html" className="dropdown-item">
              Job Opening
            </Link>
            <Link to="team.html" className="dropdown-item">
              Interview Questions
            </Link>
          </div>
        </div>
        <Link to="/contact" className="nav-item nav-link">
        Contact
        </Link>
      </div>
    </div>
  </nav>
</div>
</>

  )
}