// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import About from './MyComponent/About';
import Contact from './MyComponent/Contact';
import Home from './Screens/Home';
import Layout from './Screens/Layout';
import Course from './MyComponent/Course';
import Training from './MyComponent/Training';
import CourseListComponent from './MyComponent/CourseListComponent';
import CourseDetail from './MyComponent/CourseDetail';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={<Layout><Home /></Layout>}
          />
          <Route
            path="about"
            element={<Layout><About /></Layout>}
          />
          <Route
            path="contact"
            element={<Layout><Contact /></Layout>}
          />
          <Route
            path="course"
            element={<Layout><Course /></Layout>}
          />
          <Route
            path="training"
            element={<Layout><Training/></Layout>}
          />
          <Route
            path="coursedetail/:courseId"
            element={<Layout><CourseDetail/></Layout>}
          />
          
          <Route path='CourseListComponent' element={<CourseListComponent/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
