import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const CourseListComponent = () => {
  const [courseList, setCourseList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://localhost:8080/MTISAdminPanel1/rest/CourseDetailApi/CourseDetail', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (data.status === 'success') {
          setCourseList(data.CourseList);
          console.log(data.CourseList)
        } else {
          setError(data.message);
        }
      } catch (error) {
        setError('An error occurred while fetching the data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures that this effect runs once when the component mounts

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
 {courseList.map((course, index) => (
  <div key={index} className="col-lg-4 col-md-6 mb-4">
    <div className="card h-100 shadow">
      <img
        src={`http://localhost:8080/MTISAdminPanel1/${course.courseImg}`}
        className="card-img-top"
        alt={course.name}
        style={{ height: "200px", objectFit: "cover" }}
      />
      <div className="card-body text-center">
        <h5 className="card-title mb-2">{course.name}</h5>
        <h6 className="card-subtitle mb-3 text-muted">{course.title}</h6>
        <p className="card-text mb-3">{course.description}</p>
        <h6 className="card-text" style={{ color: "#06A3DA" }}>
          Discount: Rs.{course.discountFee} / <small>Month</small>
        </h6>
        <Link to={`../coursedetail/${course.courseId}`} className="btn btn-primary rounded mt-3">
          Learn More <i className="bi bi-arrow-right" />
        </Link>
      </div>
    </div>
  </div>
        ))}
    </>
  );
};


// {courseList.map((course, index) => (<div key={index} className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
//     <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
//     <img src='img/cyber.png' style={{height: "120px"}} alt=''/>
//       <h4 >{course.name}</h4>
//       <h5 className="mb-4">{course.title} </h5>
//       <h6 className="mb-2">
//       {course.description}
//       </h6>
//       <h5 className="" style={{ color: '#06A3DA' }}>Discount- $49.00  / <small>Month</small></h5>
//       <Link className="btn btn-lg btn-primary rounded" href="">
//         <i className="bi bi-arrow-right" />
//       </Link>
//     </div>
//         </div>))}

export default CourseListComponent;
