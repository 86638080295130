import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Carousel from '../MyComponent/Carousel'
import 'animate.css';

export default function Home() {
  const handlePhoneNumberClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };
  const YourFormComponent = () => {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      //subject: '',
      message: '',
      contact: ''
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
  e.preventDefault();

  const urlEncodedFormData = new URLSearchParams(formData).toString();

  // Replace 'http://localhost:8080/MTISAdminPanel1/rest/ContactApi/get_contact_detail' with your actual server URL and path
  fetch('http://localhost:8080/MTISAdminPanel1/rest/EnquiryApi/get_enquiry_detail', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
     
    },
    body: urlEncodedFormData,
  })
  .then(response => response.json())
  .then(data => console.log("Printing data:", data))
  .catch(error => console.error("Error:", error));
};

return (
       <form onSubmit={handleSubmit}>
        <div className="row g-3">
          <div className="col-md-6">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="form-control border-0 bg-light px-4"
              placeholder="Your Name"
              style={{ height: 55 }}
            />
          </div>
          <div className="col-md-6">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="form-control border-0 bg-light px-4"
              placeholder="Your Email"
              style={{ height: 55 }}
            />
          </div>
          <div className="col-md-12">
            <input
              type="mobile number"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              className="form-control border-0 bg-light px-4"
              placeholder="Mobile Number"
              style={{ height: 55 }}
            />
          </div>
          {/* <div className="col-12">
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className="form-control border-0 bg-light px-4"
              placeholder="Subject"
              style={{ height: 55 }}
            />
          </div> */}
          <div className="col-12">
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="form-control border-0 bg-light px-4 py-3"
              rows={4}
              placeholder="Message"
            />
          </div>
                <div className="col-12">
                  <button className="btn btn-dark w-100 py-3" type="submit">
                    Request A Quote
                  </button>
                </div>
              </div>
            </form>

    )   ;
};

return (
    
<div>
<>
<div><Carousel/></div>
  {/* Facts Start */}
  <div className="container-fluid facts py-5 pt-lg-0">
    <div className="container py-5 pt-lg-0">
      <div className="row gx-0">
        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
          <div
            className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
            style={{ height: 150 }}
          >
            <div
              className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-users text-primary" />
            </div>
            <div className="ps-4">
              <h1 className="text-white mb-0" data-toggle="counter-up">
               Online Courses
              </h1>
              <h6 className="text-white mb-0">VIEW MORE</h6>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
          <div
            className="bg-light shadow d-flex align-items-center justify-content-center p-4"
            style={{ height: 150 }}
          >
            <div
              className="bg-primary d-flex align-items-center justify-content-center rounded mb-2"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-check text-white" />
            </div>
            <div className="ps-4">
              <h1 className="mb-0" data-toggle="counter-up">
              Our Projects
              </h1>
              <h6 className="text-black mb-0">VIEW MORE</h6>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
          <div
            className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
            style={{ height: 150 }}
          >
            <div
              className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-award text-primary" />
            </div>
            <div className="ps-4">
              <h1 className="text-white mb-0" data-toggle="counter-up">
                Our Trainers
              </h1>
              <h6 className="text-white mb-0">VIEW MORE</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Facts Start */}
  {/* About Start */}
  {/* <div
  className="container-fluid bg-primary py-5 bg-header"
  style={{ marginBottom: 90 }}
>
  <div className="row py-5">
    <div className="col-12 pt-lg-5 mt-lg-5 text-center">
      <h1 className="display-4 text-white animated zoomIn">About Us</h1>
      <Link href="" className="h5 text-white">
        Home
      </Link>
      <i className="far fa-circle text-white px-2" />
      <Link href="" className="h5 text-white">
        About
      </Link>
    </div>
  </div>
</div> */}
  <div className="modal fade" id="searchModal" tabIndex={-1}>
    <div className="modal-dialog modal-fullscreen">
      <div
        className="modal-content"
        style={{ background: "rgba(9, 30, 62, .7)" }}
      >
        <div className="modal-header border-0">
          <button
            type="button"
            className="btn bg-white btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body d-flex align-items-center justify-content-center">
          <div className="input-group" style={{ maxWidth: 600 }}>
            <input
              type="text"
              className="form-control bg-transparent border-primary p-3"
              placeholder="Type search keyword"
            />
            <button className="btn btn-primary px-4">
              <i className="bi bi-search" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Full Screen Search End */}
  {/* About Start */}
  <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-7">
          <div className="section-title position-relative pb-3 mb-5">
            <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
            <h1 className="mb-0">
            Training Institute Overview
            </h1>
          </div>
          <p className="mb-4">
          Marcks Training & IT Services is a leading software training institute providing Software Training, Project Guidance, IT Consulting and Technology Workshops. Using our enhanced global software training delivery methodology, innovative software training approach and industry expertise, we provide high-value corporate training services that enable our clients to enhance business performance, accelerate time-to-market, increase productivity and improve customer service.
          </p>
          <div className="row g-0 mb-3">
            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
              <h5 className="mb-3">
                <i className="fa fa-check text-primary me-3" />
                Customized Solutions
              </h5>
              <h5 className="mb-3">
                <i className="fa fa-check text-primary me-3" />
                Professional Staff
              </h5>
            </div>
            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
              <h5 className="mb-3">
                <i className="fa fa-check text-primary me-3" />
                24/7 Support
              </h5>
              <h5 className="mb-3">
                <i className="fa fa-check text-primary me-3" />
                Fair Prices
              </h5>
            </div>
          </div>
          <div
            className="d-flex align-items-center mb-4 wow fadeIn"
            data-wow-delay="0.6s"
          >
            <div
              className="bg-primary d-flex align-items-center justify-content-center rounded"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-phone-alt text-white" />
            </div>
            <div className="ps-4">
              <h5 className="mb-2">Call to ask any question</h5>
              <h4 className="text-primary mb-0">
+91 9930067580</h4>
            </div>
          </div>
          {/* <Link
           to="quote.html"
            className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
            data-wow-delay="0.9s"
          >
            Request A Quote
          </Link> */}
        </div>
        <div className="col-lg-5" style={{ minHeight: 500 }}>
          <div className="position-relative h-100">
            <img
              className="position-absolute w-100 h-100 rounded wow zoomIn"
              data-wow-delay="0.9s"
              src="img/train3.jpg" alt="hello"
              style={{ objectFit:"cover" }}/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
  <div className="container py-5">
    <div className="row g-5">
      <div className="col-lg-5" style={{ minHeight: 500 }}>
        <div className="position-relative h-100">
          <img
            className="position-absolute w-100 h-100 rounded wow zoomIn"
            data-wow-delay="0.9s"
            src="img/vision.jpg"
            alt="hello"
            style={{ objectFit: "cover" }}
          />
        </div>
      </div>
      <div className="col-lg-7">
  <div className="section-title position-relative pb-3 mb-5">
    <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
    <h1 className="mb-0">VISION-MISSION</h1>
  </div>
  <p className="mb-4">
    To enrich the knowledge & skill sets of young software engineers by providing value added training in the areas of Software Development & Testing.
  </p>
  <p className="mb-4">
    To serve the industries by providing trained human resources in the above areas.
  </p>
  <p className="mb-4">
    To provide quality Software Training & Consulting Services in the areas of J2EE, .NET, ERP, Database Administration, Testing, Content Management with Live Projects.
  </p>
        <div
          className="d-flex align-items-center mb-4 wow fadeIn"
          data-wow-delay="0.6s"
        ></div>
      </div>
    </div>
  </div>
</div>
<div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-7">
          <div className="section-title position-relative pb-3 mb-5">
            <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
            <h1 className="mb-0">
            Our Dream
            </h1>
          </div>
          <p className="mb-4">
    Creation of MTIS and its successful journey are the result of inspiration of certain ideas, in other words, our collective dream. It’s this long cherished dream that motivates us and takes us forward.
  </p>
  <p className="mb-4">
    To provide the best-of-breed technology-based solutions, have a company of people who are proud of their company and enjoy working together, and continue to dream to make their company bigger, smarter, sweeter, reputed, and admirable.
  </p>
  <p className="mb-4">
    With this dream in brain, vision in mind, mission in hands, and values in the heart, the company was set up by a group of entrepreneurs headed by an IT professional with Headquarters in Ranchi and two other centers in Hyderabad and Coimbatore, adding up more experience in various verticals and technology platforms.
  </p>
          <div
            className="d-flex align-items-center mb-4 wow fadeIn"
            data-wow-delay="0.6s"
          >
          </div>
        </div>
        <div className="col-lg-5" style={{ minHeight: 500 }}>
          <div className="position-relative h-100">
            <img
              className="position-absolute w-100 h-100 rounded wow zoomIn"
              data-wow-delay="0.9s"
              src="img/dream.jpg" alt="hello"
              style={{ objectFit: "cover" }}/>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* About End */}
  {/* Features Start */}
  <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div
        className="section-title text-center position-relative pb-3 mb-5 mx-auto"
        style={{ maxWidth: 600 }}
      >
        <h5 className="fw-bold text-primary text-uppercase">Why Choose Us</h5>
        <h1 className="mb-0">
          We Are Here to Grow Your Business Exponentially
        </h1>
      </div>
      <div className="row g-5">
        <div className="col-lg-4">
          <div className="row g-5">
            <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
              <div
                className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                style={{ width: 60, height: 60 }}
              >
                <i className="fa fa-cubes text-white" />
              </div>
              <h4>Best In Industry</h4>
              <p className="mb-0">
              Setting the standard for excellence in the industry.
              </p>
            </div>
            <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
              <div
                className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                style={{ width: 60, height: 60 }}
              >
                <i className="fa fa-award text-white" />
              </div>
              <h4>Award Winning</h4>
              <p className="mb-0">
              Championing excellence, we stand proudly as an award-winning industry leader.
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4  wow zoomIn"
          data-wow-delay="0.9s"
          style={{ minHeight: 350 }}
        >
          <div className="position-relative h-100">
            <img
              className="position-absolute w-100 h-100 rounded wow zoomIn"
              data-wow-delay="0.1s"
              src="img/feature.jpg"
              style={{ objectFit: "cover" }} alt=''
            />
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row g-5">
            <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
              <div
                className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                style={{ width: 60, height: 60 }}
              >
                <i className="fa fa-users-cog text-white" />
              </div>
              <h4>Professional Staff</h4>
              <p className="mb-0">
              Experienced professionals, elevating standards.
              </p>
            </div>
            <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
              <div
                className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                style={{ width: 60, height: 60 }}
              >
                <i className="fa fa-phone-alt text-white" />
              </div>
              <h4>24/7 Support</h4>
              <p className="mb-0">
              Reliable 24/7 support, ensuring assistance whenever you need it.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Features Start */}
  {/* Service Start */}
  <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div
        className="section-title text-center position-relative pb-3 mb-5 mx-auto"
        style={{ maxWidth: 600 }}
      >
        <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
        <h1 className="mb-0">
          Custom IT Solutions for Your Successful Business
        </h1>
      </div>
      <div className="row g-5">
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            <div className="service-icon">
              <i className="fa fa-shield-alt text-white" />
            </div>
            <h4 className="mb-3">Classroom Training</h4>
            <p className="m-0">
            We provide best-in-class personalized training services with a broad exposure of our state-of-the art infrastructure.
            </p>
            <Link className="btn btn-lg btn-primary rounded" href="">
              <i className="bi bi-arrow-right" />
            </Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            <div className="service-icon">
              <i className="fa fa-chart-pie text-white" />
            </div>
            <h4 className="mb-3">Online Training</h4>
            <p className="m-0">
            Communicating to the training center is a hassle? No problem. We have you covered. We provided online training services with fully interactive sessions.
            </p>
            <Link className="btn btn-lg btn-primary rounded" href="">
              <i className="bi bi-arrow-right" />
            </Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            <div className="service-icon">
              <i className="fa fa-code text-white" />
            </div>
            <h4 className="mb-3">Corporate Training</h4>
            <p className="m-0">
            We undertake Corporate Trainings giving a full hands-on industry exposure.
            </p>
            <Link className="btn btn-lg btn-primary rounded" href="">
              <i className="bi bi-arrow-right" />
            </Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            <div className="service-icon">
              <i className="fab fa-android text-white" />
            </div>
            <h4 className="mb-3">College Intership Projects</h4>
            <p className="m-0">
            We undertake College Intership projects and work closely with the candidates during the course of project delivery.
            </p>
            <Link className="btn btn-lg btn-primary rounded" href="">
              <i className="bi bi-arrow-right" />
            </Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            <div className="service-icon">
              <i className="fa fa-search text-white" />
            </div>
            <h4 className="mb-3">Weekend Workshop/Training</h4>
            <p className="m-0">
            We conduct weekend workshops and trainings for students and working professionals who prefer to invest time during weekends.
            </p>
            <Link className="btn btn-lg btn-primary rounded" href="">
              <i className="bi bi-arrow-right" />
            </Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
          <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
            <div className="service-icon">
              <i className="fa fa-search text-white" />
            </div>
            <h4 className="mb-3">Placement Assistance</h4>
            <p className="m-0">
            We are here to guide and help students with job placements in various IT job roles in addition to assistance with resume writing and interview preparation.
            </p>
            <Link className="btn btn-lg btn-primary rounded" href="">
              <i className="bi bi-arrow-right" />
            </Link>
          </div>
        </div>
        {/* <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
          <div className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
            <h3 className="text-white mb-3">Call Us For Quote</h3>
            <p className="text-white mb-3">
              Clita ipsum magna kasd rebum at ipsum amet dolor justo dolor est
              magna stet eirmod
            </p>
            <h2 className="text-white mb-0">+012 345 6789</h2>
          </div>
        </div> */}
      </div>
    </div>
  </div>
  {/* Service End */}
  {/* Pricing Plan Start */}
  <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div
        className="section-title text-center position-relative pb-3 mb-5 mx-auto"
        style={{ maxWidth: 600 }}
      >
        <h5 className="fw-bold text-primary text-uppercase">Pricing Plans</h5>
        <h1 className="mb-0">
          We are Offering Competitive Prices for Our Clients
        </h1>
      </div>
      <div className="row g-0">
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="bg-light rounded">
            <div className="border-bottom py-4 px-5 mb-4">
              <h4 className="text-primary mb-1">Basic Plan</h4>
              <small className="text-uppercase">For Small Size Business</small>
              <br/>
              

            </div>
            <div className="p-5 pt-0">
            <h1 className="" style={{ color: 'black' }}>$49.00  / <small>Month</small></h1>
              
              <div className="d-flex justify-content-between mb-3">
                <span>HTML5 &amp; CSS3</span>
                <i className="fa fa-check text-primary pt-1" />
              </div>
              <div className="d-flex justify-content-between mb-3">
                <span>Bootstrap v5</span>
                <i className="fa fa-check text-primary pt-1" />
              </div>
              <div className="d-flex justify-content-between mb-3">
                <span>Responsive Layout</span>
                <i className="fa fa-times text-danger pt-1" />
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Cross-browser Support</span>
                <i className="fa fa-times text-danger pt-1" />
              </div>
              <Link href="" className="btn btn-primary py-2 px-4 mt-4">
                Order Now
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
          <div
            className="bg-white rounded shadow position-relative"
            style={{ zIndex: 1 }}
          >
            <div className="border-bottom py-4 px-5 mb-4">
              <h4 className="text-primary mb-1">Standard Plan</h4>
              <small className="text-uppercase">For Medium Size Business</small>
            </div>
            <div className="p-5 pt-0">
            <h1 className="" style={{ color: 'black' }}>$49.00  / <small>Month</small></h1>
              <div className="d-flex justify-content-between mb-3">
                <span>HTML5 &amp; CSS3</span>
                <i className="fa fa-check text-primary pt-1" />
              </div>
              <div className="d-flex justify-content-between mb-3">
                <span>Bootstrap v5</span>
                <i className="fa fa-check text-primary pt-1" />
              </div>
              <div className="d-flex justify-content-between mb-3">
                <span>Responsive Layout</span>
                <i className="fa fa-check text-primary pt-1" />
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Cross-browser Support</span>
                <i className="fa fa-times text-danger pt-1" />
              </div>
              <Link href="" className="btn btn-primary py-2 px-4 mt-4">
                Order Now
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
          <div className="bg-light rounded">
            <div className="border-bottom py-4 px-5 mb-4">
              <h4 className="text-primary mb-1">Advanced Plan</h4>
              <small className="text-uppercase">For Large Size Business</small>
            </div>
            <div className="p-5 pt-0">
            <h1 className="" style={{ color: 'black' }}>$49.00  / <small>Month</small></h1>
              <div className="d-flex justify-content-between mb-3">
                <span>HTML5 &amp; CSS3</span>
                <i className="fa fa-check text-primary pt-1" />
              </div>
              <div className="d-flex justify-content-between mb-3">
                <span>Bootstrap v5</span>
                <i className="fa fa-check text-primary pt-1" />
              </div>
              <div className="d-flex justify-content-between mb-3">
                <span>Responsive Layout</span>
                <i className="fa fa-check text-primary pt-1" />
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Cross-browser Support</span>
                <i className="fa fa-check text-primary pt-1" />
              </div>
              <Link href="" className="btn btn-primary py-2 px-4 mt-4">
                Order Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Pricing Plan End */}
  {/* Quote Start */}
  <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-7">
          <div className="section-title position-relative pb-3 mb-5">
            <h5 className="fw-bold text-primary text-uppercase">
              Enquiry Form
            </h5>
            <h1 className="mb-0">
              Need A Free Quote? Please Feel Free to Contact Us
            </h1>
          </div>
          <div className="row gx-3">
            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
              <h5 className="mb-4">
                <i className="fa fa-reply text-primary me-3" />
                Reply within 24 hours
              </h5>
            </div>
            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
              <h5 className="mb-4">
                <i className="fa fa-phone-alt text-primary me-3" />
                24 hrs telephone support
              </h5>
            </div>
          </div>
          <p className="mb-4">
          Discover the world of IT excellence with MTIS. At MTIS, we are committed to providing exceptional IT training solutions that elevate your skills and knowledge to new heights. Whether you're an aspiring professional looking to sharpen your expertise or an organization seeking to enhance your team's capabilities, MTIS is your go-to partner. Contact us now for a complimentary quote and let us tailor a training program that aligns perfectly with your objectives. Our seasoned instructors are dedicated to delivering cutting-edge training, ensuring you stay at the forefront of technology. Unlock the full potential of your IT journey with MTIS – where knowledge meets innovation. Reach out today and let's embark on this transformative learning experience together
          </p>
          <div
            className="d-flex align-items-center mt-2 wow zoomIn"
            data-wow-delay="0.6s"
          >
            <div
              className="bg-primary d-flex align-items-center justify-content-center rounded"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-phone-alt text-white" />
            </div>
            <div className="ps-4">
              <h5 className="mb-2">Call to ask any question</h5>
              <h4 className="text-primary mb-0" onClick={() => handlePhoneNumberClick('9930067580')} style={{ cursor: 'pointer' }}>
              9930067580
            </h4>
            <h4 className="text-primary mb-0" onClick={() => handlePhoneNumberClick('9029941077')} style={{ cursor: 'pointer' }}>
              9029941077
            </h4>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div
            className="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn"
            data-wow-delay="0.9s"
          >
              
            <YourFormComponent/>
              
                       </div>
        </div>
      </div>
    </div>
  </div>
  {/* Quote End */}
 <div className="container-fluid py-5">
  <div className="container py-5">
    <div className="section-title text-center position-relative pb-3 mb-4 mx-auto" style={{ maxWidth: 600 }}>
      <h1 className="mb-0">What our students say</h1>
    </div>
    <div id="testimonial-carousel" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="testimonial-item bg-light my-4">
            <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
              <img className="img-fluid rounded" src="img/testimonial-1.jpg" style={{ width: 60, height: 60 }} alt='' />
              <div className="ps-4">
                <h4 className="text-primary mb-1">Student Name</h4>
                <small className="text-uppercase">Course</small>
              </div>
            </div>
            <div className="pt-4 pb-5 px-5">
              Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="testimonial-item bg-light my-4">
            
          </div>
        </div>

      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#testimonial-carousel" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#testimonial-carousel" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</div> 

  {/* Team Start */}
  {/* <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div
        className="section-title text-center position-relative pb-3 mb-5 mx-auto"
        style={{ maxWidth: 600 }}
      >
        <h5 className="fw-bold text-primary text-uppercase">Team Members</h5>
        <h1 className="mb-0">
          Professional Stuffs Ready to Help Your Business
        </h1>
      </div>
      <div className="row g-5">
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
          <div className="team-item bg-light rounded overflow-hidden">
            <div className="team-img position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/team-1.jpg" alt="" />
              <div className="team-social">
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                  href=""
                >
                  <i className="fab fa-twitter fw-normal" />
                </Link>
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                  href=""
                >
                  <i className="fab fa-facebook-f fw-normal" />
                </Link>
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                  href=""
                >
                  <i className="fab fa-instagram fw-normal" />
                </Link>
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                  href=""
                >
                  <i className="fab fa-linkedin-in fw-normal" />
                </Link>
              </div>
            </div>
            <div className="text-center py-4">
              <h4 className="text-primary">Full Name</h4>
              <p className="text-uppercase m-0">Designation</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item bg-light rounded overflow-hidden">
            <div className="team-img position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/team-2.jpg" alt="" />
              <div className="team-social">
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                  href=""
                >
                  <i className="fab fa-twitter fw-normal" />
                </Link>
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                  href=""
                >
                  <i className="fab fa-facebook-f fw-normal" />
                </Link>
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                  href=""
                >
                  <i className="fab fa-instagram fw-normal" />
                </Link>
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                  href=""
                >
                  <i className="fab fa-linkedin-in fw-normal" />
                </Link>
              </div>
            </div>
            <div className="text-center py-4">
              <h4 className="text-primary">Full Name</h4>
              <p className="text-uppercase m-0">Designation</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
          <div className="team-item bg-light rounded overflow-hidden">
            <div className="team-img position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/team-3.jpg" alt="" />
              <div className="team-social">
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                  href=""
                >
                  <i className="fab fa-twitter fw-normal" />
                </Link>
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                  href=""
                >
                  <i className="fab fa-facebook-f fw-normal" />
                </Link>
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                  href=""
                >
                  <i className="fab fa-instagram fw-normal" />
                </Link>
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                  href=""
                >
                  <i className="fab fa-linkedin-in fw-normal" />
                </Link>
              </div>
            </div>
            <div className="text-center py-4">
              <h4 className="text-primary">Full Name</h4>
              <p className="text-uppercase m-0">Designation</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
</>

  
  </div>
  );

}