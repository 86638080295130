import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Contact() {
  const email = 'marckstech@gmail.com';
  const handlePhoneNumberClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const YourFormComponent = () => {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      subject: '',
      message: '',
      contact: ''
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
  e.preventDefault();

  const urlEncodedFormData = new URLSearchParams(formData).toString();

  // Replace 'http://localhost:8080/MTISAdminPanel1/rest/ContactApi/get_contact_detail' with your actual server URL and path
  fetch('http://localhost:8080/MTISAdminPanel1/rest/ContactApi/get_contact_detail', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
     
    },
    body: urlEncodedFormData,
  })
  .then(response => response.json())
  .then(data => console.log("Printing data:", data))
  .catch(error => console.error("Error:", error));
};
    return (
      <form onSubmit={handleSubmit}>
        <div className="row g-3">
          <div className="col-md-6">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="form-control border-0 bg-light px-4"
              placeholder="Your Name"
              style={{ height: 55 }}
            />
          </div>
          <div className="col-md-6">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="form-control border-0 bg-light px-4"
              placeholder="Your Email"
              style={{ height: 55 }}
            />
          </div>
          <div className="col-md-12">
            <input
              type="mobile number"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              className="form-control border-0 bg-light px-4"
              placeholder="Mobile Number"
              style={{ height: 55 }}
            />
          </div>
          <div className="col-12">
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className="form-control border-0 bg-light px-4"
              placeholder="Subject"
              style={{ height: 55 }}
            />
          </div>
          <div className="col-12">
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="form-control border-0 bg-light px-4 py-3"
              rows={4}
              placeholder="Message"
            />
          </div>
          <div className="col-12">
            <button className="btn btn-primary w-100 py-3" type="submit">
              Send Message
            </button>
          </div>
        </div>
      </form>
    );
  };

  return (
    <>
              <div
        className="container-fluid bg-primary py-5 bg-header"
        style={{ marginBottom: 90 }}
      >
        <div className="row py-5">
          <div className="col-12 pt-lg-5 mt-lg-5 text-center">
            <h1 className="display-4 text-white animated zoomIn">Contact Us</h1>
            <Link href="" className="h5 text-white">
              Home
            </Link>
            <i className="far fa-circle text-white px-2" />
            <Link href="" className="h5 text-white">
              Contact
            </Link>
          </div>
        </div>
      </div>
  {/* Navbar End */}
  {/* Full Screen Search Start */}
  <div className="modal fade" id="searchModal" tabIndex={-1}>
    <div className="modal-dialog modal-fullscreen">
      <div
        className="modal-content"
        style={{ background: "rgba(9, 30, 62, .7)" }}
      >
        <div className="modal-header border-0">
          <button
            type="button"
            className="btn bg-white btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body d-flex align-items-center justify-content-center">
          <div className="input-group" style={{ maxWidth: 600 }}>
            <input
              type="text"
              className="form-control bg-transparent border-primary p-3"
              placeholder="Type search keyword"
            />
            <button className="btn btn-primary px-4">
              <i className="bi bi-search" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Full Screen Search End */}
  {/* Contact Start */}
  <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div
        className="section-title text-center position-relative pb-3 mb-5 mx-auto"
        // style={{ maxWidth: 800 }}
      >
        <h5 className="fw-bold text-primary text-uppercase">Contact Us</h5>
        <h1 className="mb-0">If You Have Any Query, Feel Free To Contact Us</h1>
      </div>
      <div className="row g-5 mb-5">
        <div className="col-lg-4">
          <div
            className="d-flex align-items-center wow fadeIn"
            data-wow-delay="0.1s"
          >
            <div
              className="bg-primary d-flex align-items-center justify-content-center rounded"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-phone-alt text-white" />
            </div>
            <div className="ps-4">
            <h4 className="mb-2">Call to ask any question</h4>
            <h5 className="text-primary mb-0" onClick={() => handlePhoneNumberClick('9930067580')} style={{ cursor: 'pointer' }}>
              9930067580
            </h5>
            <h5 className="text-primary mb-0" onClick={() => handlePhoneNumberClick('9029941077')} style={{ cursor: 'pointer' }}>
              9029941077
            </h5>
          </div>
        </div>
      </div>
        <div className="col-lg-3">
          <div
            className="d-flex align-items-center wow fadeIn"
            data-wow-delay="0.4s"
          >
            <div
              className="bg-primary d-flex align-items-center justify-content-center rounded"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-envelope-open text-white" />
            </div>
            <div className="ps-3">
              <h4 className="mb-2">Email to get free quote</h4>
              <a href={`mailto:${email}`} className="text-primary mb-0">
              <h5 style={{ color: '#06A3DA' }}>{email}</h5>
      </a>
            
</div>
          </div>
        </div>
        <div className="col-lg-5">
          <div
            className="d-flex align-items-center wow fadeIn"
            data-wow-delay="0.8s"
          >
            <div
              className="bg-primary d-flex align-items-center justify-content-center rounded"
              style={{ width: 70, height: 60  }}
            >
            <i className="fa fa-map-marker-alt text-white" />
            </div>
            <div className="ps-2">
  <h4 className="mb-2">Visit our office</h4>
  <h5 className="mb-0 text-primary">T-09, 3rd Floor, Haware Centurion Mall, Sector-19A, Seawoods, NaviMumbai</h5>
</div>
          </div>
        </div>
      </div>
      <div className="row g-5">
        <div className="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
        <YourFormComponent/>
        </div>
         <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
         <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3771.890479432007!2d73.0220861!3d19.0245471!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3beaf802169%3A0x507569bece5f9141!2sMARCKS%20Training%20%26%20IT%20Services!5e0!3m2!1sen!2sin!4v1700906878233!5m2!1sen!2sin"
        width="600" 
        height="400"
        style={{ border: 0 }} 
        allowFullScreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade" 
      ></iframe>
        </div>
      </div>
    </div>
  </div>
  {/* Contact End */}
  {/* Vendor Start */}
  <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5 mb-5">
      <div className="bg-white">
        <div className="owl-carousel vendor-carousel">
          <img src="img/vendor-1.jpg" alt="" />
          <img src="img/vendor-2.jpg" alt="" />
          <img src="img/vendor-3.jpg" alt="" />
          <img src="img/vendor-4.jpg" alt="" />
          <img src="img/vendor-5.jpg" alt="" />
          <img src="img/vendor-6.jpg" alt="" />
          <img src="img/vendor-7.jpg" alt="" />
          <img src="img/vendor-8.jpg" alt="" />
          <img src="img/vendor-9.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>

      </>
  );
}
