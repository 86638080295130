
// CourseDetail.js
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const CourseDetail = () => {
    const { courseId } = useParams(); // Updated variable name
    const [course, setCourse] = useState(null);
  
    useEffect(() => {
      const fetchCourseDetails = async () => {
        try {
          const response = await fetch('http://localhost:8080/MTISAdminPanel1/rest/CourseDetailApi/getOneCourse', {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `courseId=${courseId}`, // Updated variable name
          });
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const data = await response.json();
          if (data.status === 'success') {
            setCourse(data.courseDt);
          } else {
            console.error('Error fetching course details:', data.message);
          }
        } catch (error) {
          console.error('Error fetching course details:', error);
        }
      };
  
      fetchCourseDetails();
    }, [courseId]);

  if (!course) {
    return <p>Loading...</p>;
  }

  // Render your course details using the 'course' state

  return (
   
        <>
      <div className="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: 90 }}>
        <div className="row py-5">
          <div className="col-12 pt-lg-5 mt-lg-5 text-center">
            <h1 className="display-4 text-white animated zoomIn">Course Details</h1>
            <Link to="/" className="h5 text-white">
              Course
            </Link>
            <i className="far fa-circle text-white px-2" />
            <Link to="/about" className="h5 text-white">
              Course Details
            </Link>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="card course-card shadow-lg">
          <div className="row">
            <div className="col-md-4">
            <img
        src={`http://localhost:8080/MTISAdminPanel1/${course.courseImg}`}
        // className="card-img-top"
        alt={course.courseImg}
                className="card-img-top course-image"
              />
            </div>
            <div className="col-md-8">
              <div className="card-body course-card-body">
                <h2 className="card-title course-card-title">Course Details</h2>
                <ul className="course-details-list">
                  <li>
                    <strong>Name:</strong> {course.name}
                  </li>
                  <li>
                    <strong>Title:</strong> {course.title}
                  </li>
                  <li>
                    <strong>Duration:</strong> {course.duration}
                  </li>
                  <li>
                    <strong>Fees:</strong> {course.fees}
                  </li>
                  <li>
                  <li>
                    <strong>Mode:</strong> {course.mode}
                  </li>
                    <strong>Discount Fee:</strong> {course.discoumtFee}
                  </li>
                  
                 
                </ul>
              </div>
            </div>
          </div>
          <div className="card-body course-card-body">
            <h2 className="card-title course-card-title">Description</h2>
            <p className="card-text course-card-text">
              {course.description}
            </p>
          </div>
          <div className="card-body course-card-body">
            <h2 className="card-title course-card-title">Syllabus</h2>
            <p className="card-text course-card-text">
              {course.syllabus}
            </p>
          </div>
        </div>
      </div>
      
    </>
    
  );
};

export default CourseDetail;






// import React, { useEffect, useState } from 'react';
// import { Link, useParams } from 'react-router-dom';


// const CourseDetail = () => {
//   return (
//     <>
//       <div className="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: 90 }}>
//         <div className="row py-5">
//           <div className="col-12 pt-lg-5 mt-lg-5 text-center">
//             <h1 className="display-4 text-white animated zoomIn">Course Details</h1>
//             <Link to="/" className="h5 text-white">
//               Course
//             </Link>
//             <i className="far fa-circle text-white px-2" />
//             <Link to="/about" className="h5 text-white">
//               Course Details
//             </Link>
//           </div>
//         </div>
//       </div>

//       <div className="container">
//         <div className="card course-card shadow-lg">
//           <div className="row">
//             <div className="col-md-4">
//               <img
//                 src="img/corpo.jpg"
//                 alt="Course Image"
//                 className="card-img-top course-image"
//               />
//             </div>
//             <div className="col-md-8">
//               <div className="card-body course-card-body">
//                 <h2 className="card-title course-card-title">Course Details</h2>
//                 <ul className="course-details-list">
//                   <li>
//                     <strong>Name:</strong> Course Name
//                   </li>
//                   <li>
//                     <strong>Title:</strong> Course Title
//                   </li>
//                   <li>
//                     <strong>Duration:</strong> 8 weeks
//                   </li>
//                   <li>
//                     <strong>Fees:</strong> $500
//                   </li>
//                   <li>
//                   <li>
//                     <strong>Mode:</strong> Online
//                   </li>
//                     <strong>Discount Fee:</strong> $450
//                   </li>
//                   <li>
//                     <strong>Duration:</strong> 8 weeks
//                   </li>
                 
//                 </ul>
//               </div>
//             </div>
//           </div>
//           <div className="card-body course-card-body">
//             <h2 className="card-title course-card-title">Description</h2>
//             <p className="card-text course-card-text">
//               Explore the details of our featured course. This comprehensive program covers
//               essential topics to boost your skills and knowledge. Join us on a learning journey
//               designed to provide valuable insights and practical experience.
//             </p>
//           </div>
//           <div className="card-body course-card-body">
//             <h2 className="card-title course-card-title">Syllabus</h2>
//             <p className="card-text course-card-text">
//               Syllabus details go here.
//             </p>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default CourseDetail;
