import React from 'react'
import { Link } from 'react-router-dom'
export default function Footer() {
  return (
    <div>
      <div className="container-fluid bg-dark text-light mt-5 wow fadeInUp" data-wow-delay="0.1s">
          <div className="container">
              <div className="row gx-5">
                  <div className="col-lg-4 col-md-6 footer-about">
                      <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                          <Link to="index.html" className="navbar-brand">
                          <img src='img/mlogo.png' style={{height: "50px"}} alt=''/>
                          </Link>
                          <p className="mt-3 mb-4">MTIS is a leading Software Training Institute providing software training, project guidance, IT consulting & corporate training.</p>
                          <form action="">
                              <div className="input-group">
                                  <input type="text" className="form-control border-white p-3" placeholder="Your Email"/>
                                  <button className="btn btn-dark">Sign Up</button>
                              </div>
                          </form>
                      </div>
                  </div>
                  <div className="col-lg-8 col-md-6">
                      <div className="row gx-5">
                          <div className="col-lg-4 col-md-12 pt-5 mb-5">
                              <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                  <h3 className="text-light mb-0">Get In Touch</h3>
                              </div>
                              <div className="d-flex mb-2">
                                  <i className="bi bi-geo-alt text-primary me-2"></i>
                                  <p className="mb-0">T-09, 3rd Floor, Haware Centurion Mall, Sector-19A, Seawoods, NaviMumbai</p>
                              </div>
                              <div className="d-flex mb-2">
    <i className="bi bi-envelope-open text-primary me-2"></i>
    <p className="mb-0">
        <a href="mailto:marckstech@gmail.com" style={{ color: 'white' }}>marckstech@gmail.com</a>
    </p>
</div>
<div className="d-flex mb-2">
    <i className="bi bi-telephone text-primary me-2"></i>
    <p className="mb-0">
        <a href="tel:9930067580" style={{ color: 'white' }}>9930067580</a> / <a href="tel:9029941077" style={{ color: 'white' }}>9029941077</a>
    </p>
</div>
                              <div className="d-flex mt-4">
                                  <Link className="btn btn-primary btn-square me-2" to="#"><i className="fab fa-twitter fw-normal"></i></Link>
                                  <Link className="btn btn-primary btn-square me-2" to="#"><i className="fab fa-facebook-f fw-normal"></i></Link>
                                  <Link className="btn btn-primary btn-square me-2" to="#"><i className="fab fa-linkedin-in fw-normal"></i></Link>
                                  <Link className="btn btn-primary btn-square" to="#"><i className="fab fa-instagram fw-normal"></i></Link>
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                              <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                  <h3 className="text-light mb-0">Menu</h3>
                              </div>
                              <div className="link-animated d-flex flex-column justify-content-start">
                                  <Link className="text-light mb-2" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>Home</Link>
                                  <Link className="text-light mb-2" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>About Us</Link>
                                  <Link className="text-light mb-2" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>Our Services</Link>
                                  <Link className="text-light mb-2" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>Contact</Link>
                                  {/* <Link className="text-light mb-2" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>Software Training</Link>
                                  <Link className="text-light" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>Careers</Link>
                                  <Link className="text-light" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>Contact</Link> */}
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                              <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                  <h3 className="text-light mb-0">Quick Links</h3>
                              </div>
                              <div className="link-animated d-flex flex-column justify-content-start">
                                  <Link className="text-light mb-2" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>Software Training</Link>
                                  <Link className="text-light mb-2" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>Careers</Link>
                                  <Link className="text-light mb-2" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>All Courses</Link>
                                  
                                  {/* <Link className="text-light mb-2" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>Meet The Team</Link>
                                  <Link className="text-light mb-2" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>Latest Blog</Link>
                                  <Link className="text-light" to="#"><i className="bi bi-arrow-right text-primary me-2"></i>Contact Us</Link> */}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="container-fluid text-white" style={{ background: '#061429' }}>
  
  <div className="container text-center">
      <div className="row justify-content-end">
          <div className="col-lg-8 col-md-6">
              <div className="d-flex align-items-center justify-content-center" style={{ height: '75px' }}>
                  <p className="mb-0">&copy; <Link className="text-white border-bottom" to="#">Copyright ©2023</Link>. All Rights Reserved. 
      
      
      Designed by <Link className="text-white border-bottom" to="https://marckstraining.in">MTIS </Link></p>
         <Link className="border-bottom" to="https://themewagon.com" target="_blank"></Link>
              </div>
          </div>
      </div>
  </div>
</div>

      </div>
  
     
      <Link to="#" className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i className="bi bi-arrow-up"></i></Link>

    </div>
  )
}
