import React from 'react'
import { Link } from 'react-router-dom'

export default function About() {
  return (
    <div>
      <>
      <div
  className="container-fluid bg-primary py-5 bg-header"
  style={{ marginBottom: 90 }}
>
  <div className="row py-5">
    <div className="col-12 pt-lg-5 mt-lg-5 text-center">
      <h1 className="display-4 text-white animated zoomIn">About Us</h1>
      <Link href="" className="h5 text-white">
        Home
      </Link>
      <i className="far fa-circle text-white px-2" />
      <Link href="" className="h5 text-white">
        About
      </Link>
    </div>
  </div>
</div>
  <div className="modal fade" id="searchModal" tabIndex={-1}>
    <div className="modal-dialog modal-fullscreen">
      <div
        className="modal-content"
        style={{ background: "rgba(9, 30, 62, .7)" }}
      >
        <div className="modal-header border-0">
          <button
            type="button"
            className="btn bg-white btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body d-flex align-items-center justify-content-center">
          <div className="input-group" style={{ maxWidth: 600 }}>
            <input
              type="text"
              className="form-control bg-transparent border-primary p-3"
              placeholder="Type search keyword"
            />
            <button className="btn btn-primary px-4">
              <i className="bi bi-search" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Full Screen Search End */}
  {/* About Start */}
  <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-7">
          <div className="section-title position-relative pb-3 mb-5">
            <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
            <h1 className="mb-0">
            Training Institute Overview
            </h1>
          </div>
          <p className="mb-4">
          Marcks Training & IT Services is a leading software training institute providing Software Training, Project Guidance, IT Consulting and Technology Workshops. Using our enhanced global software training delivery methodology, innovative software training approach and industry expertise, we provide high-value corporate training services that enable our clients to enhance business performance, accelerate time-to-market, increase productivity and improve customer service.
          </p>
          <div className="row g-0 mb-3">
            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
              <h5 className="mb-3">
                <i className="fa fa-check text-primary me-3" />
                Customized Solutions
              </h5>
              <h5 className="mb-3">
                <i className="fa fa-check text-primary me-3" />
                Professional Staff
              </h5>
            </div>
            <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
              <h5 className="mb-3">
                <i className="fa fa-check text-primary me-3" />
                24/7 Support
              </h5>
              <h5 className="mb-3">
                <i className="fa fa-check text-primary me-3" />
                Fair Prices
              </h5>
            </div>
          </div>
          <div
            className="d-flex align-items-center mb-4 wow fadeIn"
            data-wow-delay="0.6s"
          >
            <div
              className="bg-primary d-flex align-items-center justify-content-center rounded"
              style={{ width: 60, height: 60 }}
            >
              <i className="fa fa-phone-alt text-white" />
            </div>
            <div className="ps-4">
              <h5 className="mb-2">Call to ask any question</h5>
              <h4 className="text-primary mb-0">
+91 9930067580</h4>
            </div>
          </div>
          <Link
           to="quote.html"
            className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
            data-wow-delay="0.9s"
          >
            Request A Quote
          </Link>
        </div>
        <div className="col-lg-5" style={{ minHeight: 500 }}>
          <div className="position-relative h-100">
            <img
              className="position-absolute w-100 h-100 rounded wow zoomIn"
              data-wow-delay="0.9s"
              src="img/train3.jpg" alt="hello"
              style={{ objectFit:"cover" }}/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
  <div className="container py-5">
    <div className="row g-5">
      <div className="col-lg-5" style={{ minHeight: 500 }}>
        <div className="position-relative h-100">
          <img
            className="position-absolute w-100 h-100 rounded wow zoomIn"
            data-wow-delay="0.9s"
            src="img/vision.jpg"
            alt="hello"
            style={{ objectFit: "cover" }}
          />
        </div>
      </div>
      <div className="col-lg-7">
  <div className="section-title position-relative pb-3 mb-5">
    <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
    <h1 className="mb-0">VISION-MISSION</h1>
  </div>
  <p className="mb-4">
    To enrich the knowledge & skill sets of young software engineers by providing value added training in the areas of Software Development & Testing.
  </p>
  <p className="mb-4">
    To serve the industries by providing trained human resources in the above areas.
  </p>
  <p className="mb-4">
    To provide quality Software Training & Consulting Services in the areas of J2EE, .NET, ERP, Database Administration, Testing, Content Management with Live Projects.
  </p>
        <div
          className="d-flex align-items-center mb-4 wow fadeIn"
          data-wow-delay="0.6s"
        ></div>
      </div>
    </div>
  </div>
</div>
<div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-7">
          <div className="section-title position-relative pb-3 mb-5">
            <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
            <h1 className="mb-0">
            Our Dream
            </h1>
          </div>
          <p className="mb-4">
    Creation of MTIS and its successful journey are the result of inspiration of certain ideas, in other words, our collective dream. It’s this long cherished dream that motivates us and takes us forward.
  </p>
  <p className="mb-4">
    To provide the best-of-breed technology-based solutions, have a company of people who are proud of their company and enjoy working together, and continue to dream to make their company bigger, smarter, sweeter, reputed, and admirable.
  </p>
  <p className="mb-4">
    With this dream in brain, vision in mind, mission in hands, and values in the heart, the company was set up by a group of entrepreneurs headed by an IT professional with Headquarters in Ranchi and two other centers in Hyderabad and Coimbatore, adding up more experience in various verticals and technology platforms.
  </p>
          <div
            className="d-flex align-items-center mb-4 wow fadeIn"
            data-wow-delay="0.6s"
          >
          </div>
        </div>
        <div className="col-lg-5" style={{ minHeight: 500 }}>
          <div className="position-relative h-100">
            <img
              className="position-absolute w-100 h-100 rounded wow zoomIn"
              data-wow-delay="0.9s"
              src="img/dream.jpg" alt="hello"
              style={{ objectFit: "cover" }}/>
          </div>
        </div>
      </div>
    </div>
  </div>


  {/* <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5">
      <div
        className="section-title text-center position-relative pb-3 mb-5 mx-auto"
        style={{ maxWidth: 600 }}
      >
        <h5 className="fw-bold text-primary text-uppercase">Team Members</h5>
        <h1 className="mb-0">
          Professional Stuffs Ready to Help Your Business
        </h1>
      </div>
      <div className="row g-5">
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
          <div className="team-item bg-light rounded overflow-hidden">
            <div className="team-img position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/team-1.jpg" alt="" />
              <div className="team-social">
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                 to=""
                >
                  <i className="fab fa-twitter fw-normal" />
                </Link>
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                 to=""
                >
                  <i className="fab fa-facebook-f fw-normal" />
                </Link>
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                 to=""
                >
                  <i className="fab fa-instagram fw-normal" />
                </Link>
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                 to=""
                >
                  <i className="fab fa-linkedin-in fw-normal" />
                </Link>
              </div>
            </div>
            <div className="text-center py-4">
              <h4 className="text-primary">Full Name</h4>
              <p className="text-uppercase m-0">Designation</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div className="team-item bg-light rounded overflow-hidden">
            <div className="team-img position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/team-2.jpg" alt="" />
              <div className="team-social">
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                 to=""
                >
                  <i className="fab fa-twitter fw-normal" />
                </Link>
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                 to=""
                >
                  <i className="fab fa-facebook-f fw-normal" />
                </Link>
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                 to=""
                >
                  <i className="fab fa-instagram fw-normal" />
                </Link>
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                 to=""
                >
                  <i className="fab fa-linkedin-in fw-normal" />
                </Link>
              </div>
            </div>
            <div className="text-center py-4">
              <h4 className="text-primary">Full Name</h4>
              <p className="text-uppercase m-0">Designation</p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
          <div className="team-item bg-light rounded overflow-hidden">
            <div className="team-img position-relative overflow-hidden">
              <img className="img-fluid w-100" src="img/team-3.jpg" alt="" />
              <div className="team-social">
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                 to=""
                >
                  <i className="fab fa-twitter fw-normal" />
                </Link>
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                 to=""
                >
                  <i className="fab fa-facebook-f fw-normal" />
                </Link>
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                 to=""
                >
                  <i className="fab fa-instagram fw-normal" />
                </Link>
                <Link
                  className="btn btn-lg btn-primary btn-lg-square rounded"
                 to=""
                >
                  <i className="fab fa-linkedin-in fw-normal" />
                </Link>
              </div>
            </div>
            <div className="text-center py-4">
              <h4 className="text-primary">Full Name</h4>
              <p className="text-uppercase m-0">Designation</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
  {/* Team End */}
  {/* Vendor Start */}
  {/* <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container py-5 mb-5">
      <div className="bg-white">
        <div className="owl-carousel vendor-carousel">
          <img src="img/vendor-1.jpg" alt="" />
          <img src="img/vendor-2.jpg" alt="" />
          <img src="img/vendor-3.jpg" alt="" />
          <img src="img/vendor-4.jpg" alt="" />
          <img src="img/vendor-5.jpg" alt="" />
          <img src="img/vendor-6.jpg" alt="" />
          <img src="img/vendor-7.jpg" alt="" />
          <img src="img/vendor-8.jpg" alt="" />
          <img src="img/vendor-9.jpg" alt="" />
        </div>
      </div>
    </div>
  </div> */}
  {/* Vendor End */}
</>

    </div>
  )
}
